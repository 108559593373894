import React, { useState } from 'react'
import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from 'reactstrap'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

export const Navigation = props => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <>
      <Navbar dark expand="md" role="navigation" className="nav-menu">
        <Link className="navbar-brand" to="/">
          <StaticImage
            src="../images/Lumineers-white-logo-2-300x60.png"
            alt="Group photo"
            placeholder="none"
            layout="fixed"
            width={200}
            height={40}
          />
        </Link>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav navbar className="ml-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/community/">
                Community
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/results/">
                Results
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/procedure/">
                Procedure
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link faq-nav-item" to="/faq/">
                FAQ
              </Link>
            </li>
            <NavItem>
              <Link to="/find-a-dentist" className="find-a-dentist">
                Find a Dentist
              </Link>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </>
  )
}

export default Navigation
