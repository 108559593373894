/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'reactstrap'
import './layout.css'
import '../styles/layout.scss'
import Navigation from './Navbar'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

const Layout = ({ children }) => {
  return (
    <>
      <div>
        <Navigation />
        <main>{children}</main>
        <footer className="footer-container">
          <Container fluid={true}>
            <Row>
              <Col lg="4" className="footer-items">
                ©{new Date().getFullYear()} Den-Mat Holdings, LLC
              </Col>
              <Col lg="4" className="footer-items-center">
                <div className="text-center footer-text-links">
                  <Link to="/about-us">About Us</Link>{' '}
                  <Link to="/privacy-policy">Privacy Policy</Link>{' '}
                  <Link to="/terms-of-use">Terms of Use</Link>
                </div>
              </Col>
              <Col lg="4">
                <div className="social-media-container">
                  <div className="facebook-icon">
                    <a
                      href="https://www.facebook.com/LumineersVeneers/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <StaticImage
                        src="../images/facebook.png"
                        alt="Facebook logo"
                        placeholder="none"
                        layout="fixed"
                        width={30}
                        height={30}
                      />
                    </a>
                  </div>
                  <div className="instagram-icon">
                    <a
                      href="https://www.instagram.com/lumineersveneers/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <StaticImage
                        src="../images/instagram-logo.png"
                        alt="Instagram logo"
                        placeholder="none"
                        layout="fixed"
                        width={30}
                        height={30}
                      />
                    </a>
                  </div>
                  <div className="youtube-icon">
                    <a
                      href="https://www.youtube.com/channel/UC3CM1hqHnuFgrN10WwPfJBg"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <StaticImage
                        src="../images/youtube-circle.png"
                        alt="YouTube logo"
                        placeholder="none"
                        layout="fixed"
                        width={30}
                        height={30}
                      />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
